<template>
  <div>
    <el-dialog
  title="原因"
  :visible.sync="dialogVisible"
  width="30%"
  >
 <el-input
  type="textarea"
  :autosize="{ minRows: 2, maxRows: 4}"
  placeholder="请输入内容"
  v-model="text">
</el-input>
<div>
    <el-button type="text" v-for="(item,i) in items" :key="i" @click="itemChange(item)">{{item.name}}</el-button>
</div>

  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirm">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    show:{
        type:Boolean,
        default:false
    },
    items:{
        type:Array,
        default:()=>{return []}
    }
  },
  watch:{
    show(nv){
        this.dialogVisible = nv
    }
  },
  data() {
    return {
      dialogVisible:false,
      text:''
    };
  },

  mounted() {
    
  },

  methods: {
    itemChange(item){
        this.text = item.value || item.name
    },
    confirm(){
        if(!this.text){
            this.$message('请填写信息')
            return
        }
        this.$emit('confirm',this.text)
        this.dialogVisible = false
    }
  },
};
</script>